.quote {
    .customer-details {
        width: 100%;
        clear: both;
    }
    h2 {
        &.quote-items-header {
            color: @mainblue;
            margin-top: 15px;
        }
    }
    .items-table-wrapper {
        ul {
            list-style: none;
            li {
                padding: 5px;
                border-bottom: @tableheaderborder;
                border-top: @tableheaderborder;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                .item-details {
                    width: 75%;
                }
                .line-total, .remove-item {
                    margin: auto 0;
                }
                .remove-item {                    
                    a {
                        i {
                            &.fa-trash-can {
                                color: red;
                                font-size: 20px;
                                margin: 5px;
                            }
                        }
                    }
                    @media screen and (max-width: 400px) {
                        text-align: center;
                        width: 100%;
                    }
                }
                p {
                    &.lead-time {
                        input {
                            width: 150px;
                        }
                        label {
                            span {
                                font-weight: 400;
                                font-style: italic;
                                font-size: .8rem;
                            }
                        }
                    }
                    input {
                        &.quote-qty {
                            width: 50px;
                        }
                    }
                }
                &:nth-child(odd) {
                    background-color: @bgtableheaderlight;
                }
            }
            label {
                font-weight: bold;
                margin-right:5px;
            }
        }
        .totals {
            text-align: right;
            padding-right: 10%;
        }
    }
    table {        
        &#items_table {
            margin-top:25px;
            width: 100%;
            thead {
                th {
                    height: 40px;    
                    background-color: @bgtableheaderlight; 
                }
            }
            tbody {     
                tr {
                    &:nth-child(even){
                        background-color: @bgtableheaderlight;
                    }
                }           
                td {
                    height: 35px;    
                    padding:2px 5px;
                    &.line-count {
                        text-align: center;
                    }
                    &:nth-child(3) {
                        text-align: center;
                    }
                    &:nth-child(4), &:nth-child(5), &:nth-child(6) {
                        text-align: right;
                    }
                    &.remove, &.update {
                        text-align: center;
                    }
                    a {
                        i {
                            &.fa-trash-alt::before, &.fa-trash-can::before {
                                color: red;
                                font-size: 20px;
                                margin: 5px;
                            }
                            &.fa-check-double::before {
                                color: green;
                                margin: 5px;
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
            tfoot {                
                td {
                    height: 35px;    
                    background-color: @white; 
                    padding:2px 5px;
                    border-top: 1px solid @black;
                    text-align: right;
                    &:first-child {
                        font-weight: bold;
                    }
                }
            }
        }
    }
    #table-scroll {
        height:500px;
        overflow: auto;  
        margin-top:20px;
    }
    input[type=text] {
        margin-bottom: 12px;
        padding: .4em;
    }
    .no-border {
        border: none;
    }
    .ui-dialog {
        label {
            display: block;
        }
        input {
            display: block;
            &.text {
                margin-bottom:12px;
                width:95%;
                padding: .4em; 
            }
        }
        fieldset {
            padding:0;
            border:0;
            margin-top:25px;
        }
        h1 {
            font-size: 1.2em;
            margin: .6em 0; 
        }
        div {
            #user-contain {
                width: 350px;
                margin: 20px 0;
                table {
                    margin: 1em 0;
                    border-collapse: collapse;
                    width: 100%; 
                    td {
                        border: 1px solid #eee;
                        padding: .6em 10px;
                        text-align: left;
                    }
                    th {
                        border: 1px solid #eee;
                        padding: .6em 10px;
                        text-align: left;
                    }
                }
            }
        }
        .ui-dialog-title {
            font-size: 1.5em;
        }
        .ui-state-error { 
            padding: .3em;
        }
        .validateTips { 
            border: 1px solid transparent; 
            padding: 0.3em;
        }
        .ui-widget-header {
            background: transparent !important;
            border: none !important;    
        }
    }
    .search-stats {
        .search-error {
            display: none;
        }
        .search-record-count {
            display: none;
        }
    } 
}