.breadcrumbs {
    margin: 10px 15px;
    ul {
        list-style: none;
        li {
            display: inline;
            &:nth-child(2) {
                font-weight: 700;
                color: @darkblue;
                &::before {
                    content: ">";
                    padding: 0 7px;
                    font-size: 1rem;
                }
            }
        }
    }
}