.crm-view {
    ul {
        list-style: none;
        li {
            &.bg-lightgreen {
                background-color:@bglightgreen;
                color: @success;
            }
            &.bg-lightpink {
                background-color:@bglightpink;
                color: @error;
            }
            span {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                display: inline-block;
                margin-right: 5px;
                &.crm-green-dot {
                    background-color: @success;
                }
                &.crm-yellow-dot {
                    background-color: @yellow;
                }
                &.crm-red-dot {
                    background-color: @error;
                }
                &.crm-white-dot {
                    background-color: @white;
                }
            }
        }
    }
}