ul {    
    li {
        font-size: .9rem;
        padding: 2px 0;
    }
    &.er-list {
        margin-left: 20px;
        margin-bottom: 5px;
    }
    &.customer-applications, &.customer-industries {
        list-style: none;
        li {
            label {
                font-weight:500;
                font-size: .8rem;
                padding: 0;
                margin-left:5px;
            }
        }
    }
}