.login {
    div {
        &.login {
            width: 400px;
            background-color: @white;
            box-shadow: 0 0 9px 0 @boxshadow;
            margin: 100px auto;
            text-align: center;     
            img {
                margin-top: 20px;
            }     
            form {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                padding-top: 20px;
                p {
                    padding:0 10px;
                    font-size: .9rem;
                }
                .errors {
                    display: none;
                    width: 100%;
                    justify-content: center;
                    margin-bottom: 20px;
                    padding: 10px;
                    color: @error;
                    background-color: @errorbg;
                }
                label {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100px;
                    height: 50px;
                    background-color:@mainblue;
                    color: @white;
                    font-weight: 500;
                    font-size: .8rem;
                    padding: 0;
                }
                input {
                    &[type="password"], &[type="text"] {
                        width: 260px;
                        height: 50px;
                        border: 1px solid @bgtableheaderlight;
                        margin-bottom: 20px;
                        padding: 0 8px;
                        box-sizing: content-box;
                    }
                    &[type="submit"] {
                        width: 100%;
                        padding: 15px;
                        margin-top: 20px;
                        background-color: @mainblue;
                        border: 0;
                        cursor: pointer;
                        font-weight: bold;
                        color: @white;
                        transition: background-color 0.2s;
                        font-size: 1rem;
                        &:hover {
                            background-color:@darkblue;
                        }
                    }
                }
            }
        }
    }
}
