root { 
    display: block;
    background-color: red;
}
* {
    margin: 0;
    padding: 0;
}
body {
    font-family: @font1;
    font-size: 1rem;
    margin: auto;
    width: 100%;
    max-width: 1440px;
    //border-width: 10px;
    //border-color: black;
    h1 {
        margin: auto;
        font-weight: 500;
        &.title {
            font-size: 2.5rem;
        }
    }
    h2 {
        font-size: 1rem;
    }
    h3 {
        color: @mainblue;
        font-size: .9rem;
        line-height: 1.2rem;
        margin-top: 10px;
    }
    a {
        text-decoration: none;
        font-weight: bold;
        color:@mainblue;
        &:hover{
            color:@darkblue;
            text-decoration: underline;
        }
    }
    p {
        margin-bottom:10px;
        line-height: 1.5rem;
    }
    input {
        font-family: Montserrat;
    }
    fieldset {
        border-radius: 5px;
        -moz-border-radius: 5px;
        -webkit-border-radius: 5px;
        border: 1px solid @bgtableheaderlight;
        box-shadow: 2px 2px 1px @bgtableheaderlight;
        -moz-box-shadow: 2px 2px 1px @bgtableheaderlight;
        -webkit-box-shadow: 2px 2px 1px @bgtableheaderlight;
        padding: 5px 0 0 0;
        margin-bottom: 15px;
        legend {
            font-weight:bold;
            margin-left: 10px;
            color: @mainblue;
        }
        &.commissions {
            min-width: 950px;
        }
    }
    footer {
        background-color: @darkblue;
        min-height: 40px;
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 15px 0 0;
        .copyright {
            color: @white;
            font-size: .8rem;
        }
    }   
    .col-50 {
        float: left;
        width: 48%;
        margin-top: 6px;
        //padding: 0 5px;
        @media screen and (max-width: 600px) {
            width: 100%;
            margin-top: 0;
        }
    }
    &.login {
        background-color: @mainbluealpha;
    }
    .notifications {
        display: none;
        padding: 5px;
        background: @mainbluealpha;
        margin: 10px 0;
        text-align: center;
        height: auto;
        p {
            color:@white;
            font-weight: bold;
        }
    }
    .commission-table-container {
        .notifications {
            &.show {
                display: block;
            }
        }
    }
    .page-header {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        min-height:125px;
        flex-wrap: wrap;
        @media screen and (max-width: 555px) {
            min-height:175px;
        }
        img {
            &.logo {
                width: 129px;
                height: 57px;
            }
        }
        .topright {
            ul {
                list-style: none;
                margin: 0 0 5px 0;
                padding: 0;
                li {
                    font-size: .8rem;
                    span {
                        font-weight: bold;
                        padding-right:5px;
                    }
                }
            }
        }
    }
    .usercontainer {
        //position: relative;
        display: flex;
        flex-wrap: wrap;
        padding: 0 20px;
        justify-content: space-evenly;        
        h2 {
            text-align: center;
        }
    }
    .content {
        padding: 0 20px;
    }
}
.applicationtable {
    padding:5px;
    text-align: center;
}

.button-container {
    padding:20px;
    text-align: center;
}

.loader {
    position:relative;
    top:100px;
    left:440px;
    border: 16px solid @bgtableheaderlight;
    border-radius: 50%;
    border-top: 16px solid @mainblue;
    width: 80px;
    height: 80px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}
#customermap {
    height: 400px;  /* The height is 400 pixels */
    width: 100%;  /* The width is the width of the web page */
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
#news {
    //width: 100%;
    min-height: 200px;
    border: 1px solid @mainblue;
    padding: 5px;
    margin-bottom: 15px;
    ul {
        margin-left:15px;
    }
}
.invoicelocation {
    position: absolute;
    top: 600px;
    left: 0;
}

.ui-widget-header {
    border: none !important;
    background: none !important;
    color: @mainblue !important;
}
.required-error {
    color: @error;
    font-size: .8rem;
}