table {
    width: 100%;
    thead {        
        tr {
            th {
                font-size: .8rem;
                padding: 5px;
                border:none;
                border-top: 1px solid @tableheaderborder;
                border-bottom: 1px solid @tableheaderborder;
            }
        }
    }
    tbody {
        tr {
            &:nth-child(even){
                background-color: @bgtableheaderlight;
            }
            td {
                font-size: .8rem;
                padding: 5px;
                border:none;
            }
        }
    }
    tfoot {
        td {
            border-top: 1px solid @tableheaderborder;
            border-bottom: 1px solid @tableheaderborder;
            &.pag {
                a {
                    &.btn {
                        width: 1em;
                        height: 1.8em;
                        line-height: 1.8;
                        text-align: center;
                        cursor: pointer;
                        background: @white;
                        color: @mainblue;
                        margin-right: 0.5em;
                        font-weight: bold;
                        padding: 0;
                        &.active {
                            color:@black;
                            font-weight:bold;
                            text-align:center;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
    &#quote_listing {
        thead {
            tr {
                th {
                    &:nth-child(2) {
                        @media screen and (max-width: 600px) {
                            display: none;
                        }
                    }

                }
            }
        }
        tbody {
            tr {
                td {
                    &:first-child {
                        text-align: center;
                        a {
                            &.view-quote {
                                color: @mainblue;
                                font-weight: bold;
                                background: none;
                                border: none;
                                text-decoration: underline !important;
                                &:hover {
                                    background: none !important;
                                    border: none !important;
                                }
                            }
                        }
                    }                        
                    &:nth-child(2), &:nth-child(3) {
                        text-align: center;
                    }
                    &:nth-child(2) {
                        @media screen and (max-width: 600px) {
                            display: none;
                        }
                    }
                    &:nth-child(4) {
                        width: 225px;
                    }
                    input[type=email] {
                        margin-bottom: 0px;
                        padding: .4em;
                        border-bottom-right-radius: 3px;
                        border-bottom-left-radius: 3px;
                        border-top-right-radius: 3px;
                        border-top-left-radius: 3px;
                        @media screen and (max-width: 600px) {
                            width: 125px;
                        }                        
                    }
                }
            }
        }
    }
    &#invoice_listing {
        thead {
            tr {
                th {
                    &:nth-child(2) {
                        @media screen and (max-width: 600px) {
                            display: none;
                        }
                    }
                }
            }
        }
        tbody {
            tr {
                td {                                        
                    &:first-child, &:nth-child(2), &:nth-child(3) {
                        text-align: center;
                    }
                    &:nth-child(2) {
                        @media screen and (max-width: 600px) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    &#commission_listing {
        thead {
            tr {
                th {
                    &:first-child, &:nth-child(13) {
                        display: none;
                    }
                }
            }
        }
        tbody, tfoot {
            tr {
                td {
                    text-align: center;
                    &:first-child, &:nth-child(13) {
                        display: none;
                    }
                    &:nth-child(6) {
                        text-align: left;
                    }
                }
            }
        }
    }
    &#contacts_listing {
        thead, tbody {
            tr {
                td, th {
                    &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6) {
                        @media screen and (max-width: 600px) {
                            display: none;
                        }
                    } 
                    &.contact-details {
                        @media screen and (min-width: 600px) {
                            display: none;
                        }
                    }      
                }
            }
        }
    }
    &#notes_listing {
        tbody {
            tr {
                td {
                    &:first-child, &:nth-child(2) {
                        text-align: center;
                    }
                }
            }
        }
    }
    &#order_listing {
        thead, tbody {
            tr {
                td, th {
                    &:nth-child(4), &:nth-child(5), &:nth-child(6), &:nth-child(7) {
                        @media screen and (max-width: 600px) {
                            display: none;
                        }
                    }                    
                    &.order-details {
                        @media screen and (min-width: 600px) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    &#customer_listing {  
        thead {
            tr {
                th {
                    &:first-child {
                        width: 5%;
                    }
                    &:nth-child(2) {
                        width: 15%;
                    }
                    &:nth-child(3) {
                        width: 25%;
                    }
                    &:nth-child(4) {
                        width: 20%;
                    }
                    &:nth-child(5) {
                        width: 35%;
                    }
                }
            }
        }  
        thead, tbody {    
            tr {
                td, th{
                    &:first-child, &:nth-child(4), &:nth-child(6) {
                        @media screen and (max-width: 600px) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    &#parts_listing, &#part_results {
        thead {
            tr {
                th {
                    &:first-child{
                        width:10%;
                    }
                    &:nth-child(2) {
                        width: 40%;
                    }
                    &:nth-child(3), &:nth-child(4){
                        width:10%;
                        text-align: center;
                    }
                    &:nth-child(5) {
                        width: 30%;
                        text-align: center;
                    }
                }            
            }
        }
        tbody {
            tr {
                td {
                    padding: 2px;
                    border:none;
                    &:first-child {
                        text-align: center;
                        a {
                            &.add-part {
                                color: @mainblue;
                                font-weight: bold;
                                background: none;
                                border: none;
                                text-decoration: underline !important;
                                &:hover {
                                    background: none !important;
                                    border: none !important;
                                }
                            }
                        }
                    }
                    &:nth-child(3), &:nth-child(4) {
                        text-align: center;
                    }
                    table {
                        &#pricing_table {                        
                            border:1px solid @black;
                            tbody {
                                tr {
                                    &:nth-child(odd) {
                                        background-color: @bgtableheaderlight;
                                    }
                                    &:nth-child(even) {
                                        background-color: @white;
                                    }
                                }
                                td {
                                    text-align: center;
                                } 
                            }                           
                            @media screen and (max-width: 500px) {
                                display: none;
                            }                    
                        }
                    }
                }
            }
        }
    }
    &#history_part_results {        
        thead {
            tr {
                th {
                    &:first-child{
                        width:10%;
                    }
                    &:nth-child(2) {
                        width: 40%;                                                   
                        @media screen and (max-width: 500px) {
                            display: none;
                        }   
                    }
                    &:nth-child(3), &:nth-child(4){
                        width:10%;
                        text-align: center;
                    }
                    &:nth-child(5) {
                        width: 30%;
                        text-align: center;
                    }
                }            
            }
        }
        tbody {
            tr {
                td {
                    padding: 2px;
                    border:none;
                    &:first-child {
                        text-align: center;
                        a {
                            &.add-part {
                                color: @mainblue;
                                font-weight: bold;
                                background: none;
                                border: none;
                                text-decoration: underline !important;
                                &:hover {
                                    background: none !important;
                                    border: none !important;
                                }
                            }
                        }
                    }
                    &:nth-child(2) {
                        @media screen and (max-width: 500px) {
                            display: none;
                        }
                    }   
                    &:nth-child(3), &:nth-child(4) {
                        text-align: center;
                    }
                    table {
                        &#pricing_table {                        
                            border:1px solid @black;
                            tbody {
                                tr {
                                    &:nth-child(odd) {
                                        background-color: @bgtableheaderlight;
                                    }
                                    &:nth-child(even) {
                                        background-color: @white;
                                    }
                                }
                                td {
                                    text-align: center;
                                } 
                            }                 
                        }
                    }
                }
            }
        }
    }
    &#crm_customer_listing {
        thead, tbody {
            tr {
                td, th {
                    &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6), &:nth-child(7), &:nth-child(8), &:nth-child(9), &:nth-child(10) {
                        @media screen and (max-width: 600px) {
                            display: none;
                        }
                    }
                    &.sale-stats {
                        @media screen and (min-width: 600px) {
                            display: none;
                        }
                    }
                }                
                &.fancySearchRow {
                    th {
                        &:nth-child(11) {
                            display: none;
                        }
                    }
                }
            }
        }
        thead {
            tr {
                th {
                    text-align: center;
                    &:first-child {
                        width: 10%;
                    }
                    &:nth-child(2) {
                        width: 30%;
                    }
                    &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6), &:nth-child(7), &:nth-child(8) {
                        width: 10%;
                    }
                }
                &.fancySearchRow {
                    input {
                        &.salesfilter, &#postalFilter {
                            width: 100px;
                        }
                        &#customerIdFilter {
                            width: 100px;
                        }
                        &#customerNameFilter {
                            width: 200px;
                        }
                        &#regionFilter {
                            width: 75px;
                        }
                    }
                }
            }
        }  
        tbody {
            tr {
                td {
                    &.bg-lightgreen {
                        background-color: @bglightgreen;
                        color: @success;
                    }
                    &.bg-lightpink {
                        background-color: @bglightpink;
                        color: @error;
                    }
                    span {
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        display: inline-block;
                        margin-right: 5px;
                        &.crm-green-dot {
                            background-color: @success;
                        }
                        &.crm-yellow-dot {
                            background-color: @yellow;
                        }
                        &.crm-red-dot {
                            background-color: @error;
                        }
                        &.crm-white-dot {
                            background-color: @white;
                        }
                    }
                    &:nth-child(1), &:nth-child(2) {
                        text-align: left;
                    }
                    &:nth-child(3) {
                        text-align: center;
                    }
                    &:nth-child(4), &:nth-child(5), &:nth-child(6), &:nth-child(7), &:nth-child(8), &:nth-child(9), &:nth-child(10), &:nth-child(11) {
                        text-align: right;
                    }
                }
            }
        }  
    }
    &#crm_listing {
        thead, tbody {
            tr {
                td, th {
                    &:first-child, &:nth-child(4), &:nth-child(5), &:nth-child(6) {
                        @media screen and (max-width: 720px) {
                            display: none;
                        }
                    }
                    &.notes-column {
                        @media screen and (min-width: 720px) {
                            display: none;
                        }
                        button {
                            width: 100%;
                        }
                    }
                }
                &.fancySearchRow {
                    th {
                        &:nth-child(7) {
                            display: none;
                        }
                    }
                }
            }            
        }
        thead {
            tr {
                th {
                    text-align: center;
                    &:first-child {
                        width: 10%;
                    }
                    &:nth-child(2) {
                        width: 10%;
                    }
                    &:nth-child(3) {
                        width: 25%;
                    }
                    &:nth-child(4) {
                        width: 45%;
                    }
                    &:nth-child(5), &:nth-child(6) {
                        width: 10%;
                    }
                }
            }
        }
        tbody {
            tr {
                td {                    
                    &:nth-child(1), &:nth-child(2) {
                        text-align: center;
                    }
                    &:nth-child(3), &:nth-child(4) {
                        text-align: left;
                    }
                    &:nth-child(5), &:nth-child(6) {
                        text-align: center;
                    }
                }
            }
        }
    }
    &#current_orders {
        tbody, thead {
            tr {
                td, th {
                    &:nth-child(4), &:nth-child(5), &:nth-child(6), &:nth-child(7) {
                        @media screen and (max-width: 500px) {
                            display: none;
                        }
                    }
                    &:nth-child(8) {
                        display: none;
                        @media screen and (max-width: 500px) {
                            display: block;
                        }
                    }
                }
            }
        }
    }
    &#order_history {
        tbody, thead {
            tr {
                td, th {
                    &:nth-child(4), &:nth-child(5), &:nth-child(6) {
                        @media screen and (max-width: 500px) {
                            display: none;
                        }
                    }
                    &:nth-child(7) {
                        display: none;
                        @media screen and (max-width: 500px) {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}
#contact_details_dialog {
    table {
        tbody {
            tr {
                th {
                    text-align: left;
                }
                &:first-child {
                    td {
                        text-align: center;
                    }
                }
            }
        }
    }
}
#table-scroll {
    height:500px;
    overflow: auto;  
    margin-top:20px;
}
#table-scroll-150 {
    height:150px;
    overflow:auto;  
    margin-top:20px;
}
#table-scroll-850 {
    height:850px;
    overflow: auto;  
    margin-top:20px;
}