@charset "UTF-8";
root {
  display: block;
  background-color: red;
}
* {
  margin: 0;
  padding: 0;
}
body {
  font-family: Montserrat;
  font-size: 1rem;
  margin: auto;
  width: 100%;
  max-width: 1440px;
}
body h1 {
  margin: auto;
  font-weight: 500;
}
body h1.title {
  font-size: 2.5rem;
}
body h2 {
  font-size: 1rem;
}
body h3 {
  color: #61b9e7;
  font-size: 0.9rem;
  line-height: 1.2rem;
  margin-top: 10px;
}
body a {
  text-decoration: none;
  font-weight: bold;
  color: #61b9e7;
}
body a:hover {
  color: #1a365d;
  text-decoration: underline;
}
body p {
  margin-bottom: 10px;
  line-height: 1.5rem;
}
body input {
  font-family: Montserrat;
}
body fieldset {
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border: 1px solid #ededed;
  box-shadow: 2px 2px 1px #ededed;
  -moz-box-shadow: 2px 2px 1px #ededed;
  -webkit-box-shadow: 2px 2px 1px #ededed;
  padding: 5px 0 0 0;
  margin-bottom: 15px;
}
body fieldset legend {
  font-weight: bold;
  margin-left: 10px;
  color: #61b9e7;
}
body fieldset.commissions {
  min-width: 950px;
}
body footer {
  background-color: #1a365d;
  min-height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 15px 0 0;
}
body footer .copyright {
  color: #ffffff;
  font-size: 0.8rem;
}
body .col-50 {
  float: left;
  width: 48%;
  margin-top: 6px;
}
@media screen and (max-width: 600px) {
  body .col-50 {
    width: 100%;
    margin-top: 0;
  }
}
body.login {
  background-color: rgba(97, 185, 231, 0.6);
}
body .notifications {
  display: none;
  padding: 5px;
  background: rgba(97, 185, 231, 0.6);
  margin: 10px 0;
  text-align: center;
  height: auto;
}
body .notifications p {
  color: #ffffff;
  font-weight: bold;
}
body .commission-table-container .notifications.show {
  display: block;
}
body .page-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  min-height: 125px;
  flex-wrap: wrap;
}
@media screen and (max-width: 555px) {
  body .page-header {
    min-height: 175px;
  }
}
body .page-header img.logo {
  width: 129px;
  height: 57px;
}
body .page-header .topright ul {
  list-style: none;
  margin: 0 0 5px 0;
  padding: 0;
}
body .page-header .topright ul li {
  font-size: 0.8rem;
}
body .page-header .topright ul li span {
  font-weight: bold;
  padding-right: 5px;
}
body .usercontainer {
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
  justify-content: space-evenly;
}
body .usercontainer h2 {
  text-align: center;
}
body .content {
  padding: 0 20px;
}
.applicationtable {
  padding: 5px;
  text-align: center;
}
.button-container {
  padding: 20px;
  text-align: center;
}
.loader {
  position: relative;
  top: 100px;
  left: 440px;
  border: 16px solid #ededed;
  border-radius: 50%;
  border-top: 16px solid #61b9e7;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}
#customermap {
  height: 400px;
  /* The height is 400 pixels */
  width: 100%;
  /* The width is the width of the web page */
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#news {
  min-height: 200px;
  border: 1px solid #61b9e7;
  padding: 5px;
  margin-bottom: 15px;
}
#news ul {
  margin-left: 15px;
}
.invoicelocation {
  position: absolute;
  top: 600px;
  left: 0;
}
.ui-widget-header {
  border: none !important;
  background: none !important;
  color: #61b9e7 !important;
}
.required-error {
  color: #d8000c;
  font-size: 0.8rem;
}
.create-quote-btn {
  background-color: #61b9e7;
  color: #ffffff;
  border: none;
  padding: 10px 15px;
  font-weight: bold;
}
.create-quote-btn:hover {
  background-color: #fd7421;
}
.part-search-btn {
  background-color: #61b9e7;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  font-weight: bold;
}
.part-search-btn:hover {
  background-color: #fd7421;
}
.email-quote-btn {
  background-color: #61b9e7;
  color: #ffffff;
  border: none;
  padding: 5px 15px;
  font-weight: bold;
}
.email-quote-btn:hover {
  background-color: #fd7421;
}
.ui-widget input.add-to-quote-btn {
  background-color: #61b9e7;
  color: #ffffff;
  border: none;
  padding: 10px 15px;
  font-weight: bold;
}
.ui-widget input.add-to-quote-btn:hover {
  background-color: #fd7421;
}
.btn-container {
  width: 100%;
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
  margin-bottom: 5px;
}
.btn {
  background-color: #61b9e7;
  color: #ffffff;
  border: none;
  padding: 12px 20px;
  font-weight: bold;
  text-decoration: none;
  font-size: 0.9rem;
}
.btn:hover {
  background-color: #fd7421;
}
.btn.logout {
  padding: 5px 15px;
}
.btn.logout:hover {
  text-decoration: none;
  color: #ffffff;
}
.btn.clear {
  background-color: #ededed;
  color: #000000;
  border: none;
  padding: 12px 20px;
  font-weight: bold;
}
@media screen and (min-width: 600px) {
  .btn.clear {
    margin-right: 15px;
  }
}
.btn.clear:hover {
  text-decoration: underline;
}
@media screen and (min-width: 500px) {
  .btn.view-brackets {
    display: none;
  }
}
.btn.view-history {
  text-decoration: none;
  font-size: 0.9rem;
  cursor: pointer;
}
@media screen and (max-width: 600px) {
  .btn.view-history {
    width: 100%;
    margin-top: 0;
  }
}
.btn.view-history:hover {
  background-color: #fd7421;
  color: #ffffff;
}
#buttonbar {
  background: none;
  border: none;
  outline: none;
  color: #61b9e7;
  cursor: pointer;
  font-weight: bold;
  padding: 0;
}
#buttonbar:hover {
  text-decoration: underline;
  color: #1a365d;
}
button {
  background-color: #61b9e7;
  color: #ffffff;
  border: none;
  padding: 12px 20px;
  font-weight: bold;
  text-decoration: none;
  font-size: 0.9rem;
  cursor: pointer;
}
@media screen and (max-width: 600px) {
  button {
    width: 100%;
    margin-top: 0;
  }
}
button:hover {
  background-color: #fd7421;
}
input[type="submit"] {
  background-color: #61b9e7;
  color: #ffffff;
  border: none;
  padding: 5px 15px;
  font-weight: bold;
  text-decoration: none;
  font-size: 0.9rem;
}
input[type="submit"]:hover {
  background-color: #fd7421;
}
table {
  width: 100%;
}
table thead tr th {
  font-size: 0.8rem;
  padding: 5px;
  border: none;
  border-top: 1px solid #cbcbcb;
  border-bottom: 1px solid #cbcbcb;
}
table tbody tr:nth-child(even) {
  background-color: #ededed;
}
table tbody tr td {
  font-size: 0.8rem;
  padding: 5px;
  border: none;
}
table tfoot td {
  border-top: 1px solid #cbcbcb;
  border-bottom: 1px solid #cbcbcb;
}
table tfoot td.pag a.btn {
  width: 1em;
  height: 1.8em;
  line-height: 1.8;
  text-align: center;
  cursor: pointer;
  background: #ffffff;
  color: #61b9e7;
  margin-right: 0.5em;
  font-weight: bold;
  padding: 0;
}
table tfoot td.pag a.btn.active {
  color: #000000;
  font-weight: bold;
  text-align: center;
  font-size: 16px;
}
@media screen and (max-width: 600px) {
  table#quote_listing thead tr th:nth-child(2) {
    display: none;
  }
}
table#quote_listing tbody tr td:first-child {
  text-align: center;
}
table#quote_listing tbody tr td:first-child a.view-quote {
  color: #61b9e7;
  font-weight: bold;
  background: none;
  border: none;
  text-decoration: underline !important;
}
table#quote_listing tbody tr td:first-child a.view-quote:hover {
  background: none !important;
  border: none !important;
}
table#quote_listing tbody tr td:nth-child(2),
table#quote_listing tbody tr td:nth-child(3) {
  text-align: center;
}
@media screen and (max-width: 600px) {
  table#quote_listing tbody tr td:nth-child(2) {
    display: none;
  }
}
table#quote_listing tbody tr td:nth-child(4) {
  width: 225px;
}
table#quote_listing tbody tr td input[type=email] {
  margin-bottom: 0px;
  padding: 0.4em;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
@media screen and (max-width: 600px) {
  table#quote_listing tbody tr td input[type=email] {
    width: 125px;
  }
}
@media screen and (max-width: 600px) {
  table#invoice_listing thead tr th:nth-child(2) {
    display: none;
  }
}
table#invoice_listing tbody tr td:first-child,
table#invoice_listing tbody tr td:nth-child(2),
table#invoice_listing tbody tr td:nth-child(3) {
  text-align: center;
}
@media screen and (max-width: 600px) {
  table#invoice_listing tbody tr td:nth-child(2) {
    display: none;
  }
}
table#commission_listing thead tr th:first-child,
table#commission_listing thead tr th:nth-child(13) {
  display: none;
}
table#commission_listing tbody tr td,
table#commission_listing tfoot tr td {
  text-align: center;
}
table#commission_listing tbody tr td:first-child,
table#commission_listing tfoot tr td:first-child,
table#commission_listing tbody tr td:nth-child(13),
table#commission_listing tfoot tr td:nth-child(13) {
  display: none;
}
table#commission_listing tbody tr td:nth-child(6),
table#commission_listing tfoot tr td:nth-child(6) {
  text-align: left;
}
@media screen and (max-width: 600px) {
  table#contacts_listing thead tr td:nth-child(3),
  table#contacts_listing tbody tr td:nth-child(3),
  table#contacts_listing thead tr th:nth-child(3),
  table#contacts_listing tbody tr th:nth-child(3),
  table#contacts_listing thead tr td:nth-child(4),
  table#contacts_listing tbody tr td:nth-child(4),
  table#contacts_listing thead tr th:nth-child(4),
  table#contacts_listing tbody tr th:nth-child(4),
  table#contacts_listing thead tr td:nth-child(5),
  table#contacts_listing tbody tr td:nth-child(5),
  table#contacts_listing thead tr th:nth-child(5),
  table#contacts_listing tbody tr th:nth-child(5),
  table#contacts_listing thead tr td:nth-child(6),
  table#contacts_listing tbody tr td:nth-child(6),
  table#contacts_listing thead tr th:nth-child(6),
  table#contacts_listing tbody tr th:nth-child(6) {
    display: none;
  }
}
@media screen and (min-width: 600px) {
  table#contacts_listing thead tr td.contact-details,
  table#contacts_listing tbody tr td.contact-details,
  table#contacts_listing thead tr th.contact-details,
  table#contacts_listing tbody tr th.contact-details {
    display: none;
  }
}
table#notes_listing tbody tr td:first-child,
table#notes_listing tbody tr td:nth-child(2) {
  text-align: center;
}
@media screen and (max-width: 600px) {
  table#order_listing thead tr td:nth-child(4),
  table#order_listing tbody tr td:nth-child(4),
  table#order_listing thead tr th:nth-child(4),
  table#order_listing tbody tr th:nth-child(4),
  table#order_listing thead tr td:nth-child(5),
  table#order_listing tbody tr td:nth-child(5),
  table#order_listing thead tr th:nth-child(5),
  table#order_listing tbody tr th:nth-child(5),
  table#order_listing thead tr td:nth-child(6),
  table#order_listing tbody tr td:nth-child(6),
  table#order_listing thead tr th:nth-child(6),
  table#order_listing tbody tr th:nth-child(6),
  table#order_listing thead tr td:nth-child(7),
  table#order_listing tbody tr td:nth-child(7),
  table#order_listing thead tr th:nth-child(7),
  table#order_listing tbody tr th:nth-child(7) {
    display: none;
  }
}
@media screen and (min-width: 600px) {
  table#order_listing thead tr td.order-details,
  table#order_listing tbody tr td.order-details,
  table#order_listing thead tr th.order-details,
  table#order_listing tbody tr th.order-details {
    display: none;
  }
}
table#customer_listing thead tr th:first-child {
  width: 5%;
}
table#customer_listing thead tr th:nth-child(2) {
  width: 15%;
}
table#customer_listing thead tr th:nth-child(3) {
  width: 25%;
}
table#customer_listing thead tr th:nth-child(4) {
  width: 20%;
}
table#customer_listing thead tr th:nth-child(5) {
  width: 35%;
}
@media screen and (max-width: 600px) {
  table#customer_listing thead tr td:first-child,
  table#customer_listing tbody tr td:first-child,
  table#customer_listing thead tr th:first-child,
  table#customer_listing tbody tr th:first-child,
  table#customer_listing thead tr td:nth-child(4),
  table#customer_listing tbody tr td:nth-child(4),
  table#customer_listing thead tr th:nth-child(4),
  table#customer_listing tbody tr th:nth-child(4),
  table#customer_listing thead tr td:nth-child(6),
  table#customer_listing tbody tr td:nth-child(6),
  table#customer_listing thead tr th:nth-child(6),
  table#customer_listing tbody tr th:nth-child(6) {
    display: none;
  }
}
table#parts_listing thead tr th:first-child,
table#part_results thead tr th:first-child {
  width: 10%;
}
table#parts_listing thead tr th:nth-child(2),
table#part_results thead tr th:nth-child(2) {
  width: 40%;
}
table#parts_listing thead tr th:nth-child(3),
table#part_results thead tr th:nth-child(3),
table#parts_listing thead tr th:nth-child(4),
table#part_results thead tr th:nth-child(4) {
  width: 10%;
  text-align: center;
}
table#parts_listing thead tr th:nth-child(5),
table#part_results thead tr th:nth-child(5) {
  width: 30%;
  text-align: center;
}
table#parts_listing tbody tr td,
table#part_results tbody tr td {
  padding: 2px;
  border: none;
}
table#parts_listing tbody tr td:first-child,
table#part_results tbody tr td:first-child {
  text-align: center;
}
table#parts_listing tbody tr td:first-child a.add-part,
table#part_results tbody tr td:first-child a.add-part {
  color: #61b9e7;
  font-weight: bold;
  background: none;
  border: none;
  text-decoration: underline !important;
}
table#parts_listing tbody tr td:first-child a.add-part:hover,
table#part_results tbody tr td:first-child a.add-part:hover {
  background: none !important;
  border: none !important;
}
table#parts_listing tbody tr td:nth-child(3),
table#part_results tbody tr td:nth-child(3),
table#parts_listing tbody tr td:nth-child(4),
table#part_results tbody tr td:nth-child(4) {
  text-align: center;
}
table#parts_listing tbody tr td table#pricing_table,
table#part_results tbody tr td table#pricing_table {
  border: 1px solid #000000;
}
table#parts_listing tbody tr td table#pricing_table tbody tr:nth-child(odd),
table#part_results tbody tr td table#pricing_table tbody tr:nth-child(odd) {
  background-color: #ededed;
}
table#parts_listing tbody tr td table#pricing_table tbody tr:nth-child(even),
table#part_results tbody tr td table#pricing_table tbody tr:nth-child(even) {
  background-color: #ffffff;
}
table#parts_listing tbody tr td table#pricing_table tbody td,
table#part_results tbody tr td table#pricing_table tbody td {
  text-align: center;
}
@media screen and (max-width: 500px) {
  table#parts_listing tbody tr td table#pricing_table,
  table#part_results tbody tr td table#pricing_table {
    display: none;
  }
}
table#history_part_results thead tr th:first-child {
  width: 10%;
}
table#history_part_results thead tr th:nth-child(2) {
  width: 40%;
}
@media screen and (max-width: 500px) {
  table#history_part_results thead tr th:nth-child(2) {
    display: none;
  }
}
table#history_part_results thead tr th:nth-child(3),
table#history_part_results thead tr th:nth-child(4) {
  width: 10%;
  text-align: center;
}
table#history_part_results thead tr th:nth-child(5) {
  width: 30%;
  text-align: center;
}
table#history_part_results tbody tr td {
  padding: 2px;
  border: none;
}
table#history_part_results tbody tr td:first-child {
  text-align: center;
}
table#history_part_results tbody tr td:first-child a.add-part {
  color: #61b9e7;
  font-weight: bold;
  background: none;
  border: none;
  text-decoration: underline !important;
}
table#history_part_results tbody tr td:first-child a.add-part:hover {
  background: none !important;
  border: none !important;
}
@media screen and (max-width: 500px) {
  table#history_part_results tbody tr td:nth-child(2) {
    display: none;
  }
}
table#history_part_results tbody tr td:nth-child(3),
table#history_part_results tbody tr td:nth-child(4) {
  text-align: center;
}
table#history_part_results tbody tr td table#pricing_table {
  border: 1px solid #000000;
}
table#history_part_results tbody tr td table#pricing_table tbody tr:nth-child(odd) {
  background-color: #ededed;
}
table#history_part_results tbody tr td table#pricing_table tbody tr:nth-child(even) {
  background-color: #ffffff;
}
table#history_part_results tbody tr td table#pricing_table tbody td {
  text-align: center;
}
@media screen and (max-width: 600px) {
  table#crm_customer_listing thead tr td:nth-child(3),
  table#crm_customer_listing tbody tr td:nth-child(3),
  table#crm_customer_listing thead tr th:nth-child(3),
  table#crm_customer_listing tbody tr th:nth-child(3),
  table#crm_customer_listing thead tr td:nth-child(4),
  table#crm_customer_listing tbody tr td:nth-child(4),
  table#crm_customer_listing thead tr th:nth-child(4),
  table#crm_customer_listing tbody tr th:nth-child(4),
  table#crm_customer_listing thead tr td:nth-child(5),
  table#crm_customer_listing tbody tr td:nth-child(5),
  table#crm_customer_listing thead tr th:nth-child(5),
  table#crm_customer_listing tbody tr th:nth-child(5),
  table#crm_customer_listing thead tr td:nth-child(6),
  table#crm_customer_listing tbody tr td:nth-child(6),
  table#crm_customer_listing thead tr th:nth-child(6),
  table#crm_customer_listing tbody tr th:nth-child(6),
  table#crm_customer_listing thead tr td:nth-child(7),
  table#crm_customer_listing tbody tr td:nth-child(7),
  table#crm_customer_listing thead tr th:nth-child(7),
  table#crm_customer_listing tbody tr th:nth-child(7),
  table#crm_customer_listing thead tr td:nth-child(8),
  table#crm_customer_listing tbody tr td:nth-child(8),
  table#crm_customer_listing thead tr th:nth-child(8),
  table#crm_customer_listing tbody tr th:nth-child(8),
  table#crm_customer_listing thead tr td:nth-child(9),
  table#crm_customer_listing tbody tr td:nth-child(9),
  table#crm_customer_listing thead tr th:nth-child(9),
  table#crm_customer_listing tbody tr th:nth-child(9),
  table#crm_customer_listing thead tr td:nth-child(10),
  table#crm_customer_listing tbody tr td:nth-child(10),
  table#crm_customer_listing thead tr th:nth-child(10),
  table#crm_customer_listing tbody tr th:nth-child(10) {
    display: none;
  }
}
@media screen and (min-width: 600px) {
  table#crm_customer_listing thead tr td.sale-stats,
  table#crm_customer_listing tbody tr td.sale-stats,
  table#crm_customer_listing thead tr th.sale-stats,
  table#crm_customer_listing tbody tr th.sale-stats {
    display: none;
  }
}
table#crm_customer_listing thead tr.fancySearchRow th:nth-child(11),
table#crm_customer_listing tbody tr.fancySearchRow th:nth-child(11) {
  display: none;
}
table#crm_customer_listing thead tr th {
  text-align: center;
}
table#crm_customer_listing thead tr th:first-child {
  width: 10%;
}
table#crm_customer_listing thead tr th:nth-child(2) {
  width: 30%;
}
table#crm_customer_listing thead tr th:nth-child(3),
table#crm_customer_listing thead tr th:nth-child(4),
table#crm_customer_listing thead tr th:nth-child(5),
table#crm_customer_listing thead tr th:nth-child(6),
table#crm_customer_listing thead tr th:nth-child(7),
table#crm_customer_listing thead tr th:nth-child(8) {
  width: 10%;
}
table#crm_customer_listing thead tr.fancySearchRow input.salesfilter,
table#crm_customer_listing thead tr.fancySearchRow input#postalFilter {
  width: 100px;
}
table#crm_customer_listing thead tr.fancySearchRow input#customerIdFilter {
  width: 100px;
}
table#crm_customer_listing thead tr.fancySearchRow input#customerNameFilter {
  width: 200px;
}
table#crm_customer_listing thead tr.fancySearchRow input#regionFilter {
  width: 75px;
}
table#crm_customer_listing tbody tr td.bg-lightgreen {
  background-color: #dff2bf;
  color: #4f8a10;
}
table#crm_customer_listing tbody tr td.bg-lightpink {
  background-color: #ffd2d2;
  color: #d8000c;
}
table#crm_customer_listing tbody tr td span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
table#crm_customer_listing tbody tr td span.crm-green-dot {
  background-color: #4f8a10;
}
table#crm_customer_listing tbody tr td span.crm-yellow-dot {
  background-color: #d1f933;
}
table#crm_customer_listing tbody tr td span.crm-red-dot {
  background-color: #d8000c;
}
table#crm_customer_listing tbody tr td span.crm-white-dot {
  background-color: #ffffff;
}
table#crm_customer_listing tbody tr td:nth-child(1),
table#crm_customer_listing tbody tr td:nth-child(2) {
  text-align: left;
}
table#crm_customer_listing tbody tr td:nth-child(3) {
  text-align: center;
}
table#crm_customer_listing tbody tr td:nth-child(4),
table#crm_customer_listing tbody tr td:nth-child(5),
table#crm_customer_listing tbody tr td:nth-child(6),
table#crm_customer_listing tbody tr td:nth-child(7),
table#crm_customer_listing tbody tr td:nth-child(8),
table#crm_customer_listing tbody tr td:nth-child(9),
table#crm_customer_listing tbody tr td:nth-child(10),
table#crm_customer_listing tbody tr td:nth-child(11) {
  text-align: right;
}
@media screen and (max-width: 720px) {
  table#crm_listing thead tr td:first-child,
  table#crm_listing tbody tr td:first-child,
  table#crm_listing thead tr th:first-child,
  table#crm_listing tbody tr th:first-child,
  table#crm_listing thead tr td:nth-child(4),
  table#crm_listing tbody tr td:nth-child(4),
  table#crm_listing thead tr th:nth-child(4),
  table#crm_listing tbody tr th:nth-child(4),
  table#crm_listing thead tr td:nth-child(5),
  table#crm_listing tbody tr td:nth-child(5),
  table#crm_listing thead tr th:nth-child(5),
  table#crm_listing tbody tr th:nth-child(5),
  table#crm_listing thead tr td:nth-child(6),
  table#crm_listing tbody tr td:nth-child(6),
  table#crm_listing thead tr th:nth-child(6),
  table#crm_listing tbody tr th:nth-child(6) {
    display: none;
  }
}
@media screen and (min-width: 720px) {
  table#crm_listing thead tr td.notes-column,
  table#crm_listing tbody tr td.notes-column,
  table#crm_listing thead tr th.notes-column,
  table#crm_listing tbody tr th.notes-column {
    display: none;
  }
}
table#crm_listing thead tr td.notes-column button,
table#crm_listing tbody tr td.notes-column button,
table#crm_listing thead tr th.notes-column button,
table#crm_listing tbody tr th.notes-column button {
  width: 100%;
}
table#crm_listing thead tr.fancySearchRow th:nth-child(7),
table#crm_listing tbody tr.fancySearchRow th:nth-child(7) {
  display: none;
}
table#crm_listing thead tr th {
  text-align: center;
}
table#crm_listing thead tr th:first-child {
  width: 10%;
}
table#crm_listing thead tr th:nth-child(2) {
  width: 10%;
}
table#crm_listing thead tr th:nth-child(3) {
  width: 25%;
}
table#crm_listing thead tr th:nth-child(4) {
  width: 45%;
}
table#crm_listing thead tr th:nth-child(5),
table#crm_listing thead tr th:nth-child(6) {
  width: 10%;
}
table#crm_listing tbody tr td:nth-child(1),
table#crm_listing tbody tr td:nth-child(2) {
  text-align: center;
}
table#crm_listing tbody tr td:nth-child(3),
table#crm_listing tbody tr td:nth-child(4) {
  text-align: left;
}
table#crm_listing tbody tr td:nth-child(5),
table#crm_listing tbody tr td:nth-child(6) {
  text-align: center;
}
@media screen and (max-width: 500px) {
  table#current_orders tbody tr td:nth-child(4),
  table#current_orders thead tr td:nth-child(4),
  table#current_orders tbody tr th:nth-child(4),
  table#current_orders thead tr th:nth-child(4),
  table#current_orders tbody tr td:nth-child(5),
  table#current_orders thead tr td:nth-child(5),
  table#current_orders tbody tr th:nth-child(5),
  table#current_orders thead tr th:nth-child(5),
  table#current_orders tbody tr td:nth-child(6),
  table#current_orders thead tr td:nth-child(6),
  table#current_orders tbody tr th:nth-child(6),
  table#current_orders thead tr th:nth-child(6),
  table#current_orders tbody tr td:nth-child(7),
  table#current_orders thead tr td:nth-child(7),
  table#current_orders tbody tr th:nth-child(7),
  table#current_orders thead tr th:nth-child(7) {
    display: none;
  }
}
table#current_orders tbody tr td:nth-child(8),
table#current_orders thead tr td:nth-child(8),
table#current_orders tbody tr th:nth-child(8),
table#current_orders thead tr th:nth-child(8) {
  display: none;
}
@media screen and (max-width: 500px) {
  table#current_orders tbody tr td:nth-child(8),
  table#current_orders thead tr td:nth-child(8),
  table#current_orders tbody tr th:nth-child(8),
  table#current_orders thead tr th:nth-child(8) {
    display: block;
  }
}
@media screen and (max-width: 500px) {
  table#order_history tbody tr td:nth-child(4),
  table#order_history thead tr td:nth-child(4),
  table#order_history tbody tr th:nth-child(4),
  table#order_history thead tr th:nth-child(4),
  table#order_history tbody tr td:nth-child(5),
  table#order_history thead tr td:nth-child(5),
  table#order_history tbody tr th:nth-child(5),
  table#order_history thead tr th:nth-child(5),
  table#order_history tbody tr td:nth-child(6),
  table#order_history thead tr td:nth-child(6),
  table#order_history tbody tr th:nth-child(6),
  table#order_history thead tr th:nth-child(6) {
    display: none;
  }
}
table#order_history tbody tr td:nth-child(7),
table#order_history thead tr td:nth-child(7),
table#order_history tbody tr th:nth-child(7),
table#order_history thead tr th:nth-child(7) {
  display: none;
}
@media screen and (max-width: 500px) {
  table#order_history tbody tr td:nth-child(7),
  table#order_history thead tr td:nth-child(7),
  table#order_history tbody tr th:nth-child(7),
  table#order_history thead tr th:nth-child(7) {
    display: block;
  }
}
#contact_details_dialog table tbody tr th {
  text-align: left;
}
#contact_details_dialog table tbody tr:first-child td {
  text-align: center;
}
#table-scroll {
  height: 500px;
  overflow: auto;
  margin-top: 20px;
}
#table-scroll-150 {
  height: 150px;
  overflow: auto;
  margin-top: 20px;
}
#table-scroll-850 {
  height: 850px;
  overflow: auto;
  margin-top: 20px;
}
.quote .customer-details {
  width: 100%;
  clear: both;
}
.quote h2.quote-items-header {
  color: #61b9e7;
  margin-top: 15px;
}
.quote .items-table-wrapper ul {
  list-style: none;
}
.quote .items-table-wrapper ul li {
  padding: 5px;
  border-bottom: #cbcbcb;
  border-top: #cbcbcb;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.quote .items-table-wrapper ul li .item-details {
  width: 75%;
}
.quote .items-table-wrapper ul li .line-total,
.quote .items-table-wrapper ul li .remove-item {
  margin: auto 0;
}
.quote .items-table-wrapper ul li .remove-item a i.fa-trash-can {
  color: red;
  font-size: 20px;
  margin: 5px;
}
@media screen and (max-width: 400px) {
  .quote .items-table-wrapper ul li .remove-item {
    text-align: center;
    width: 100%;
  }
}
.quote .items-table-wrapper ul li p.lead-time input {
  width: 150px;
}
.quote .items-table-wrapper ul li p.lead-time label span {
  font-weight: 400;
  font-style: italic;
  font-size: 0.8rem;
}
.quote .items-table-wrapper ul li p input.quote-qty {
  width: 50px;
}
.quote .items-table-wrapper ul li:nth-child(odd) {
  background-color: #ededed;
}
.quote .items-table-wrapper ul label {
  font-weight: bold;
  margin-right: 5px;
}
.quote .items-table-wrapper .totals {
  text-align: right;
  padding-right: 10%;
}
.quote table#items_table {
  margin-top: 25px;
  width: 100%;
}
.quote table#items_table thead th {
  height: 40px;
  background-color: #ededed;
}
.quote table#items_table tbody tr:nth-child(even) {
  background-color: #ededed;
}
.quote table#items_table tbody td {
  height: 35px;
  padding: 2px 5px;
}
.quote table#items_table tbody td.line-count {
  text-align: center;
}
.quote table#items_table tbody td:nth-child(3) {
  text-align: center;
}
.quote table#items_table tbody td:nth-child(4),
.quote table#items_table tbody td:nth-child(5),
.quote table#items_table tbody td:nth-child(6) {
  text-align: right;
}
.quote table#items_table tbody td.remove,
.quote table#items_table tbody td.update {
  text-align: center;
}
.quote table#items_table tbody td a i.fa-trash-alt::before,
.quote table#items_table tbody td a i.fa-trash-can::before {
  color: red;
  font-size: 20px;
  margin: 5px;
}
.quote table#items_table tbody td a i.fa-check-double::before {
  color: green;
  margin: 5px;
  font-size: 20px;
}
.quote table#items_table tfoot td {
  height: 35px;
  background-color: #ffffff;
  padding: 2px 5px;
  border-top: 1px solid #000000;
  text-align: right;
}
.quote table#items_table tfoot td:first-child {
  font-weight: bold;
}
.quote #table-scroll {
  height: 500px;
  overflow: auto;
  margin-top: 20px;
}
.quote input[type=text] {
  margin-bottom: 12px;
  padding: 0.4em;
}
.quote .no-border {
  border: none;
}
.quote .ui-dialog label {
  display: block;
}
.quote .ui-dialog input {
  display: block;
}
.quote .ui-dialog input.text {
  margin-bottom: 12px;
  width: 95%;
  padding: 0.4em;
}
.quote .ui-dialog fieldset {
  padding: 0;
  border: 0;
  margin-top: 25px;
}
.quote .ui-dialog h1 {
  font-size: 1.2em;
  margin: 0.6em 0;
}
.quote .ui-dialog div #user-contain {
  width: 350px;
  margin: 20px 0;
}
.quote .ui-dialog div #user-contain table {
  margin: 1em 0;
  border-collapse: collapse;
  width: 100%;
}
.quote .ui-dialog div #user-contain table td {
  border: 1px solid #eee;
  padding: 0.6em 10px;
  text-align: left;
}
.quote .ui-dialog div #user-contain table th {
  border: 1px solid #eee;
  padding: 0.6em 10px;
  text-align: left;
}
.quote .ui-dialog .ui-dialog-title {
  font-size: 1.5em;
}
.quote .ui-dialog .ui-state-error {
  padding: 0.3em;
}
.quote .ui-dialog .validateTips {
  border: 1px solid transparent;
  padding: 0.3em;
}
.quote .ui-dialog .ui-widget-header {
  background: transparent !important;
  border: none !important;
}
.quote .search-stats .search-error {
  display: none;
}
.quote .search-stats .search-record-count {
  display: none;
}
.customer-view .expired {
  font-weight: bold;
  color: #d8000c;
  background-color: #ffd2d2;
  padding: 5px;
}
.customer-view .customer-details {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.customer-view .customer-details div.left {
  width: 100%;
}
.customer-view .customer-details div.left p {
  margin: 10px 0;
  font-size: 0.9rem;
  line-height: 1.5rem;
}
.customer-view .customer-details div.left p.discount-code span,
.customer-view .customer-details div.left p.tax-details span {
  font-weight: 700;
  font-size: 0.9rem;
  padding-right: 10px;
}
.customer-view .customer-details div.right {
  width: 90%;
  padding: 0 5%;
}
.ribbon {
  background-color: #1a365d;
  margin-bottom: 15px;
  z-index: 999999;
}
@media only screen and (max-width: 768px) {
}
.ribbon ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: flex;
  justify-content: space-evenly;
}
@media only screen and (max-width: 768px) {
  .ribbon ul {
    position: fixed;
    left: -100%;
    top: 12rem;
    flex-direction: column;
    background-color: #ffffff;
    width: 100%;
    text-align: center;
    transition: 0.3s;
    z-index: 99;
  }
  .ribbon ul.active {
    left: 0;
  }
}
@media only screen and (max-width: 555px) {
  .ribbon ul {
    top: 0rem;
  }
}
.ribbon ul li {
  float: left;
  background-color: #1a365d;
}
.ribbon ul li a {
  display: block;
  color: #ffffff;
  background-color: #1a365d;
  font-size: 0.9rem;
  padding: 14px 10px;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
}
@media only screen and (max-width: 768px) {
  .ribbon ul li a {
    padding: 14px 0;
  }
}
.ribbon ul li a:hover,
.ribbon ul li a:active {
  background-color: #fd7421;
}
@media only screen and (max-width: 768px) {
  .ribbon ul li a:hover,
  .ribbon ul li a:active {
    width: 100%;
  }
}
.ribbon .hamburger,
.ribbon .hamburger-active {
  display: none;
  padding: 14px 0;
  background-color: #1a365d;
}
@media only screen and (max-width: 768px) {
  .ribbon .hamburger,
  .ribbon .hamburger-active {
    display: block;
    cursor: pointer;
  }
  .ribbon .hamburger.active .bar,
  .ribbon .hamburger-active.active .bar {
    z-index: 999;
  }
  .ribbon .hamburger.active .bar:nth-child(2),
  .ribbon .hamburger-active.active .bar:nth-child(2) {
    opacity: 0;
  }
  .ribbon .hamburger.active .bar:nth-child(1),
  .ribbon .hamburger-active.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  .ribbon .hamburger.active .bar:nth-child(3),
  .ribbon .hamburger-active.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
}
.ribbon .hamburger .bar,
.ribbon .hamburger-active .bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #ffffff;
}
.login div.login {
  width: 400px;
  background-color: #ffffff;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.3);
  margin: 100px auto;
  text-align: center;
}
.login div.login img {
  margin-top: 20px;
}
.login div.login form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 20px;
}
.login div.login form p {
  padding: 0 10px;
  font-size: 0.9rem;
}
.login div.login form .errors {
  display: none;
  width: 100%;
  justify-content: center;
  margin-bottom: 20px;
  padding: 10px;
  color: #d8000c;
  background-color: #ffd2d2;
}
.login div.login form label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 50px;
  background-color: #61b9e7;
  color: #ffffff;
  font-weight: 500;
  font-size: 0.8rem;
  padding: 0;
}
.login div.login form input[type="password"],
.login div.login form input[type="text"] {
  width: 260px;
  height: 50px;
  border: 1px solid #ededed;
  margin-bottom: 20px;
  padding: 0 8px;
  box-sizing: content-box;
}
.login div.login form input[type="submit"] {
  width: 100%;
  padding: 15px;
  margin-top: 20px;
  background-color: #61b9e7;
  border: 0;
  cursor: pointer;
  font-weight: bold;
  color: #ffffff;
  transition: background-color 0.2s;
  font-size: 1rem;
}
.login div.login form input[type="submit"]:hover {
  background-color: #1a365d;
}
form h3 span.same-as-billing {
  font-weight: 400;
  color: #000000;
  font-size: 0.8rem;
}
form h3 span.same-as-billing input {
  margin-left: 10px;
}
form label {
  font-weight: bold;
  font-size: 0.9rem;
  padding: 12px 12px 12px 0;
  display: inline-block;
}
form label.required:after {
  content: '*';
  color: #d8000c;
  font-size: 1.5rem;
  position: absolute;
  margin-left: 5px;
}
form input.search-field {
  margin: 0 5px;
  text-transform: uppercase;
}
@media screen and (max-width: 600px) {
  form input.search-field {
    margin: 0;
  }
}
form input[type="text"],
form input[type="password"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ededed;
  box-sizing: border-box;
  resize: vertical;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
form input[type="submit"] {
  padding: 12px 20px;
  border: none;
  cursor: pointer;
  float: right;
}
@media screen and (max-width: 600px) {
  form input[type="submit"] {
    width: 100%;
    margin-top: 0;
  }
}
form input:invalid {
  border: 2px solid #d8000c;
}
form input:invalid:required {
  background-image: linear-gradient(to right, #ededed, #ededed);
}
form input:valid {
  border: 1px solid #ededed;
}
form select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ededed;
  box-sizing: border-box;
  resize: vertical;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  background: none;
}
form select:invalid {
  border: 2px solid #d8000c;
}
form select:invalid:required {
  background-image: linear-gradient(to right, #ededed, #ededed);
}
form select:valid {
  border: 1px solid #ededed;
}
form textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ededed;
  box-sizing: border-box;
  resize: vertical;
  height: 100px;
}
form textarea:invalid {
  border: 2px solid #d8000c;
}
form textarea:invalid:required {
  background-image: linear-gradient(to right, #ededed, #ededed);
}
form textarea:valid {
  border: 1px solid #ededed;
}
form .row {
  padding: 5px;
}
form .row .col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}
@media screen and (max-width: 600px) {
  form .row .col-25 {
    width: 100%;
    margin-top: 0;
    padding: 0;
    margin-bottom: 5px;
  }
}
form .row .col-33 {
  float: left;
  width: 32%;
  margin-top: 6px;
}
@media screen and (max-width: 600px) {
  form .row .col-33 {
    width: 100%;
    margin-top: 0;
    padding: 0;
    margin-bottom: 5px;
  }
}
form .row .col-50 {
  float: left;
  width: 48%;
  margin-top: 6px;
}
@media screen and (max-width: 600px) {
  form .row .col-50 {
    width: 100%;
    margin-top: 0;
    padding: 0;
    margin-bottom: 5px;
  }
}
form .row .col-75 {
  float: left;
  width: 75%;
  margin-top: 6px;
}
@media screen and (max-width: 600px) {
  form .row .col-75 {
    width: 100%;
    margin-top: 0;
    padding: 0;
    margin-bottom: 5px;
  }
}
form .row:after {
  content: "";
  display: table;
  clear: both;
}
form span.required {
  color: #d8000c;
  font-size: 1.5rem;
  position: relative;
  top: 8px;
  font-weight: bold;
}
form.quote-form label {
  padding: 12px 12px 0 0;
}
form.commission-form {
  margin-bottom: 10px;
}
form.commission-form .form-row {
  display: flex;
  justify-content: space-evenly;
}
form.commission-form .form-row select.year {
  width: 150px;
}
form.commission-form .form-row select.month {
  width: 150px;
}
form.commission-form .form-row a {
  margin-top: 10px;
}
@media screen and (max-width: 600px) {
  .ui-dialog #applications_dialog form#appsindsform {
    display: block;
  }
}
ul li {
  font-size: 0.9rem;
  padding: 2px 0;
}
ul.er-list {
  margin-left: 20px;
  margin-bottom: 5px;
}
ul.customer-applications,
ul.customer-industries {
  list-style: none;
}
ul.customer-applications li label,
ul.customer-industries li label {
  font-weight: 500;
  font-size: 0.8rem;
  padding: 0;
  margin-left: 5px;
}
.crm-view ul {
  list-style: none;
}
.crm-view ul li.bg-lightgreen {
  background-color: #dff2bf;
  color: #4f8a10;
}
.crm-view ul li.bg-lightpink {
  background-color: #ffd2d2;
  color: #d8000c;
}
.crm-view ul li span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
.crm-view ul li span.crm-green-dot {
  background-color: #4f8a10;
}
.crm-view ul li span.crm-yellow-dot {
  background-color: #d1f933;
}
.crm-view ul li span.crm-red-dot {
  background-color: #d8000c;
}
.crm-view ul li span.crm-white-dot {
  background-color: #ffffff;
}
.breadcrumbs {
  margin: 10px 15px;
}
.breadcrumbs ul {
  list-style: none;
}
.breadcrumbs ul li {
  display: inline;
}
.breadcrumbs ul li:nth-child(2) {
  font-weight: 700;
  color: #1a365d;
}
.breadcrumbs ul li:nth-child(2)::before {
  content: ">";
  padding: 0 7px;
  font-size: 1rem;
}
