.create-quote-btn {
    background-color: @mainblue;
    color: @white;
    border: none;
    padding: 10px 15px;
    font-weight: bold;
    &:hover {
        background-color: @orange;
    }
}
.part-search-btn {
    background-color: @mainblue;
    color: @white;
    border: none;
    padding: 5px 10px;
    font-weight: bold;
    &:hover {
        background-color: @orange;
    }
}
.email-quote-btn {
    background-color: @mainblue;
    color: @white;
    border: none;
    padding: 5px 15px;
    font-weight: bold;
    &:hover {
        background-color:@orange;
    }
} 
.ui-widget {
    input {
        &.add-to-quote-btn {
            background-color: @mainblue;
            color: @white;
            border: none;
            padding: 10px 15px;
            font-weight: bold;
            &:hover {
                background-color: @orange;
            }
        }
    }
}
.btn-container {
    width:100%;
    display: flex;
    justify-content: end;
    flex-wrap: wrap;
    margin-bottom: 5px;
}
.btn {
    background-color: @mainblue;
    color: @white;
    border: none;
    padding: 12px 20px;
    font-weight: bold;
    text-decoration: none;
    font-size: .9rem;
    &:hover {
        background-color:@orange;
    }
    &.logout {
        padding: 5px 15px;
        &:hover {
            text-decoration: none;
            color: @white;
        }
    }
    &.clear {
        background-color: @bgtableheaderlight;
        color: @black;
        border: none;
        padding: 12px 20px;
        font-weight: bold;
        //margin-top:10px;
        @media screen and (min-width: 600px) {
            margin-right:15px;
        }
        &:hover {
            text-decoration: underline;
        }
    }
    &.view-brackets {
        @media screen and (min-width: 500px) {
            display: none;
        }
    }
    &.view-history {
        //background-color: @mainblue;
        //color: @white;
        //border: none;
        //padding: 12px 20px;
        //font-weight: bold;
        text-decoration: none;
        font-size: .9rem;
        cursor: pointer;
        @media screen and (max-width: 600px) {
            width: 100%;
            margin-top: 0;
        }
        &:hover {
            background-color:@orange;
            color: @white;
        }
    }
}
#buttonbar  {
    background: none;
    border: none;
    outline: none;
    color: @mainblue;
    cursor: pointer;
    font-weight: bold;
    padding: 0;
    &:hover {
        text-decoration: underline;
        color: @darkblue;
    }
}
button {
    background-color: @mainblue;
    color: @white;
    border: none;
    padding: 12px 20px;
    font-weight: bold;
    text-decoration: none;
    font-size: .9rem;
    cursor: pointer;
    @media screen and (max-width: 600px) {
        width: 100%;
        margin-top: 0;
    }
    &:hover {
        background-color:@orange;
    }
}
input {
    &[type="submit"] {
        background-color: @mainblue;
        color: @white;
        border: none;
        padding: 5px 15px;
        font-weight: bold;
        text-decoration: none;
        font-size: .9rem;
        &:hover {
            background-color:@orange;
        }   
    }
}