.ribbon {
    background-color: @darkblue;
    margin-bottom: 15px;
    z-index: 999999;
    @media only screen and (max-width: 768px) {
        //width: 60px;
        //position: fixed;
        //top: 80px;
    }
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        display:flex;
        justify-content:space-evenly;
        @media only screen and (max-width: 768px) {
            position: fixed;
            left: -100%;
            top: 12rem;
            flex-direction: column;
            background-color: @white;
            width: 100%;
            text-align: center;
            transition: 0.3s;
            z-index: 99;            
            &.active {
                left: 0;
            }
        }
        @media only screen and (max-width: 555px) {
            top: 0rem;
        }
        li {
            float: left;
            background-color: @darkblue;
            a {
                display: block;
                color: @white;
                background-color: @darkblue;
                font-size: .9rem;                
                padding: 14px 10px;
                text-align: center; 
                text-decoration: none;
                font-weight: 500;
                @media only screen and (max-width: 768px) {
                    padding: 14px 0;
                }
                &:hover, &:active {
                    background-color: @orange;
                    @media only screen and (max-width: 768px) {
                        width: 100%;
                    }
                }
            }
        }
    }
    .hamburger, .hamburger-active {
        display: none;         
        padding: 14px 0;
        //position: fixed;
        background-color: @darkblue;
        //width: 60px;   
        //z-index: 9;
        @media only screen and (max-width: 768px) {
            display: block;
            cursor: pointer;
            &.active {
                .bar {
                    z-index: 999;
                    &:nth-child(2) {
                        opacity: 0;
                    }
                    &:nth-child(1) {
                        transform: translateY(8px) rotate(45deg);
                    }
                    &:nth-child(3) {
                        transform: translateY(-8px) rotate(-45deg);
                    }
                }
            }
        }
        .bar {
            display: block;
            width: 25px;
            height: 3px;
            margin: 5px auto;
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
            background-color: @white;
        }
    }    
}