.customer-view {
    .expired {
        font-weight: bold;
        color:@error;
        background-color: @errorbg;
        padding: 5px;
    }    
    .customer-details {
        position:relative;
        display:flex;
        flex-wrap:wrap;
        justify-content:space-evenly;
        div {
            &.left {
                width:100%;
                //padding:0 5%;                
                p {     
                    margin: 10px 0;
                    font-size: .9rem;
                    line-height: 1.5rem;               
                    &.discount-code, &.tax-details {                        
                        span {
                            font-weight: 700;
                            font-size: .9rem;
                            padding-right: 10px;
                        }
                    }
                }
            }
            &.right {
                width:90%;
                padding:0 5%;
            }
        }
    }
}